export function FormDownloadModal() {
    const body = document.querySelector("body");

    const registrationModal = document.getElementById('form-download-modal');
    const closeRegistrationModal = document.getElementById('close-form-download-modal');

    const btnsOpenModal = document.querySelectorAll<HTMLElement>('[data-modal]');

    if(btnsOpenModal.length) {
      for(const btnModal of btnsOpenModal) {
        if(btnModal?.dataset.modal === 'form-download') {
          btnModal.addEventListener('click', () => {
            registrationModal?.classList.remove('invisible');
            registrationModal?.classList.add('opacity-100');
            body?.classList.add('overflow-hidden');
          });
        }
      }

      if(closeRegistrationModal) {
        closeRegistrationModal.addEventListener('click', () => {
          registrationModal?.classList.remove('opacity-100');
          registrationModal?.classList.add('invisible');

          body?.classList.remove('overflow-hidden');
        });
      }
    }
}
