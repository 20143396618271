import Swiper from "swiper";
import {Autoplay, EffectFade, Navigation, Pagination} from "swiper/modules";

import {Header} from "./Header.ts";
import {Blog} from './Blog.ts';
import {SwiperClients} from "./SwiperClients.ts";
import {SwiperReviews} from "./SwiperReviews.ts";
import {SwiperIndustries} from "./SwiperIndustries.ts";
import {FAQ} from './FAQ.ts';
import {ExpandableFeatures} from "./ExpandableFeatures.ts";
import {SwiperShiftTimeAuditing} from "./SwiperShiftTimeAuditing.ts";
import {Toast} from "./Toast.ts";
import {SwiperFeatures} from "./SwiperFeatures.ts";
import {YoutubeModal} from "./YoutubeModal.ts";
import {PopupInfoModal} from "./PopupInfoModal.ts";
import {RegistrationModal} from "./RegistrationModal.ts";
import {ContactForm} from "./ContactForm.ts";
import {DownloadPDFForm} from "./DownloadPDFForm.ts";
import {NewsletterForm} from "./NewsletterForm.ts";
import {GleenChat} from "./GleenChat.ts";
import {ZendeskChat} from "./ZendeskChat.ts";
import {BacklinkForm} from "./BacklinkForm.ts";
import {FormDownloadModal} from "./FormDownloadModal.ts";

Swiper.use([Autoplay, Pagination, Navigation, EffectFade]);

window.addEventListener('DOMContentLoaded', () => {
  Header();
  Blog();
  GleenChat();
  ZendeskChat();
  SwiperClients();
  SwiperReviews();
  SwiperIndustries();
  SwiperShiftTimeAuditing();
  SwiperFeatures();
  FAQ();
  ExpandableFeatures();
  Toast();
  YoutubeModal();
  PopupInfoModal();
  RegistrationModal();
  ContactForm();
  DownloadPDFForm();
  NewsletterForm();
  BacklinkForm();
  FormDownloadModal();
});
