import {formInterceptor} from "./form-interceptor.ts";
import {z} from "zod";

const schema = z.object({
  fname: z.string().min(1, 'This field is required'),
  lname: z.string().min(1, 'This field is required'),
  email: z.string().email('This field is required'),
  number: z.string().min(1, 'This field is required'),
  company: z.string().min(1, 'This field is required'),
  domainAuthority: z.string().min(1, 'This field is required'),
  relevantWebsitesElf: z.string().min(1, 'This field is required'),
  relevantWebsitesYourSite: z.string().min(1, 'This field is required'),
  relevantWebsitesOurBacklinks: z.string().min(1, 'This field is required'),
});

export function BacklinkForm() {
  formInterceptor<{message: string}, typeof schema>({
    selector: '[data-form="backlinkForm"]',
    schema,
    onSuccess({response}) {
      if(response) {
        window.Toast.show({
          type: 'success',
          message: response.message,
        });
      }
    }
  });
}
